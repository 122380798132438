import React, { createContext, useEffect, useReducer } from "react"
import { navigate } from "@reach/router"

import locales from "../../config/locales"
import useLocalStorage from "../hooks/useLocalStorage"

const LocaleContext = createContext()

function reducer(state, { type, locale }) {
  switch (type) {
    case "UPDATE_LOCALE":
      return { ...state, activeLocale: locale }
    default:
      throw new Error("Invalid action")
  }
}
const formatLocale = (locale) => {
  return locale?.split("-")[1] ? locale?.split("-")[1] : locale
}

function LocaleProvider({
  children,
  locale,
  locales,
  location: { pathname, search },
}) {
  const [savedLocale, saveLocale] = useLocalStorage(
    "graphcms-swag-store",
    JSON.stringify({
      activeLocale: formatLocale(locale)?.toLowerCase(),
    })
  )

  const [state, dispatch] = useReducer(reducer, JSON.parse(savedLocale))
  const [, localePath] = pathname.split("/")

  const updateLocale = (locale) => {
    const localeCode = formatLocale(locale)
    navigate(`/${localeCode?.toLowerCase()}${pathname.substring(3)}${search}`)
  }

  useEffect(() => {
    if (
      localePath !== state.activeLocale &&
      locales?.some((language) => {
        const code = language.attributes.code?.toLowerCase()
        return formatLocale(code) === localePath
      })
    )
      dispatch({ type: "UPDATE_LOCALE", locale: localePath })
  }, [localePath, locales, state.activeLocale])

  useEffect(() => {
    saveLocale(JSON.stringify(state))
  }, [state, saveLocale])

  return (
    <LocaleContext.Provider
      value={{
        ...state,
        updateLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export { LocaleProvider, LocaleContext as default }
